/* Basic editor styles */
.tiptap {
  min-height: 400px;
}

.ProseMirror {
  composes:
    global(px-4)
    global(pt-2)
  ;

  outline: none !important;
}

.ProseMirror > * {
  padding: 1rem 0;
}

.tiptap {
  width: 800px;
}

.tiptap .ProseMirror {
  position: relative;
  padding: 2.5rem 1rem;
  width: 100%;
}

.tiptap .ProseMirror > h1,
.tiptap .ProseMirror > h2 {
  text-transform: uppercase;
}

.tiptap .ProseMirror > h1 {
  margin: 0;
  font-size: theme('fontSize.l');
}

.tiptap .ProseMirror > h2 {
  margin: 0;
  font-size: theme('fontSize.m');
}

.tiptap .node-iframely {
  margin: 1rem 0;
  max-width: 800px;
}

.tiptap .node-image {
  margin: 1rem 0;
  max-width: 800px;
}

.tiptap .node-iframely .not-prose {
  margin: 0;
  shadow: none;
}

/* Contain external images loaded via copy paste */
.tiptap div[data-node-view-wrapper] {
  max-width: 100% !important;
}

.tiptap p.is-empty::before {
  color: theme('colors.grey.primary');
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror:not(.dragging) .ProseMirror-selectednode {
  outline: none !important;
  background-color: theme('colors.grey.lightest');
  transition: background-color 0.2s;
  box-shadow: none;
}

.prosemirror-dropcursor-block {
  background-color: theme('colors.grey.light') !important;
}

.drag-handle {
  padding: 1.5rem;
  margin-top: -3px;
  position: fixed;
  opacity: 1;
  transition: opacity ease-in 0.2s;
  border-radius: 0.25rem;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='fill: rgba(0, 0, 0, 0.5)'%3E%3Cpath d='M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: calc(0.6em + 0.375rem) calc(0.6em + 0.375rem);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 100;
  cursor: grab;

  &:hover {
    background-color: theme('colors.grey.lightest');
    transition: background-color 0.2s;
  }

  &:active {
    background-color: theme('colors.grey.lightest');
    transition: background-color 0.2s;
    cursor: grabbing;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  @media screen and (max-width: 600px) {
    display: none;
    pointer-events: none;
  }
}
